<template>
  <div class="profile">
    <v-row dense>
      <v-col align="center">
        <h1>Profile</h1>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <pp-account></pp-account>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="6">
        <pp-password></pp-password>
      </v-col>
    </v-row>

  </div>
</template>

<script>

  export default {
    name: "Profile.vue",
    data() {
      return {

      };
    },
    methods: {

    },
  };
</script>

<style lang="scss">

</style>
